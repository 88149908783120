<template>
<v-lazy transition="scroll-x-transition">

    <v-main>
      
<section class="hero-section">
   
  <div class="card-grid">
      <router-link class="card" v-tilt="{glare: true, reverse: true }" :to="item.to" v-for="item in items" :key="item.id" >

      <v-img :lazy-src="item.src " :src="item.src " class="card__background" ></v-img>
      <div class="card__content">
        <p class="card__category">{{ item.category}}</p>
        <h3 class="card__heading">{{item.heading}}</h3>
      </div>
      </router-link>
 </div>
      
</section>
    </v-main>
</v-lazy>
</template>

<script>
export default {
    name: 'BlurryCards',
    data() {
      return {
        items:[
          {
            id:1,
            to: '/portfolio/moftahk',
            src:  require('../assets/portfolio/tucson.jpg'),
            category: 'Marketplace',
            heading: 'Moftahk'
          },
           {
            id:2,
            to: '/portfolio/menasave',
            src: require('../assets/portfolio/ecommerce2.webp'),
            category: 'E-Commerce',
            heading: 'MenaSave'
          },
           {
            id:3,
            to: '/portfolio/the-urban-episode',
            src:  require('../assets/portfolio/The Urban Episode Cover.jpg'),
            category: 'Architecture/Urban Planning',
            heading: 'The Urban Episode'
          },
          {
              id:4,
              to: '/portfolio/maryud-fm',
              src:  require('../assets/portfolio/YP2A7488.jpg'),
              category: 'Radio Station',
              heading: 'Maryud 103 FM'
           },
          {
            id:5,
            to: '/portfolio/karmakol',
            src:  require('../assets/portfolio/karmakol.jpg'),
            category: 'Events/Festival',
            heading: 'Karmakol'
          }
        ]
      }
    },
}
</script>

<style lang="scss">
@import '../sass/blurycards.css'
    
</style>