<template>
   <v-main>
       <Header></Header>
            <div class="section4">
          <v-row> 
                <v-col >
                    <div class="aurora2">
                <!-- <v-col lg="4" md="4" sm="0"> -->

                <!-- </v-col> -->

        <v-img max-height="30em" contain  min-height="20em" class="image align-center white--text" :src="require('../assets/Resized Pictures/_DSC4784.webp')"  :lazy-src="require('../assets/Resized Pictures/_DSC4784.webp')">
         </v-img> 
                    </div>

                </v-col>
            </v-row>


         <v-container>
            <v-row>
              <v-col>
                  <h1>
                      OUR WORK
                  </h1>
                  <h3>
                     From tourism websites, to Ecommerce mobile applications. The Code Effect has various experience in producing solutions that will help your business grow and unlock its full potential.
                      </h3>
               </v-col>
                <!-- </div> -->
            </v-row>
          </v-container>
       <BluryCards></BluryCards>
       <Footer></Footer>
            </div>
   </v-main>
</template>

<script>
import Header from '../components/Header'
import Footer from '../components/Footer'
import BluryCards from '../components/BluryCards'

export default {
    name: 'Services',
    components: {
    Header,
    Footer,
    BluryCards
    },
    

}

</script>
